import './electronics.css';
import Header from "../Navbar";
import Footer from "../Footer";
import AU from "./images/Store_Logo/AU.png";
import Airtel from "./images/Store_Logo/Airtel.png";
import American_Express from "./images/Store_Logo/American_Express.png";
import BOB from "./images/Store_Logo/BOB.svg";
import Buddy_Score from "./images/Store_Logo/Buddy_Score.png";
import Credilio from "./images/Store_Logo/Credilio.png";
import Creditlinks from "./images/Store_Logo/Creditlinks.png";
import HDFC from "./images/Store_Logo/HDFC.png";
import ICICI from "./images/Store_Logo/ICICI.png";
import IDFC from "./images/Store_Logo/IDFC.png";
import IndusInd from "./images/Store_Logo/IndusInd.png";
import Max_Life from "./images/Store_Logo/Max_Life.png";
import Nexo from "./images/Store_Logo/Nexo.png";
import Paytm from "./images/Store_Logo/Paytm.png";
import Standard_Chartered from "./images/Store_Logo/Standard_Chartered.png";
import Tata_Capital from "./images/Store_Logo/Tata_Capital.png";
const Finance = () => {
  return(
    <>

    <Header />
      <div className="all_store_main">
        <div className="all_store_heading">
          <h1>Online Home & Kitchen Store</h1>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD8N" className="store">
          <div className="trending_store">
            <img src={AU} className="Amazon" />
          </div>
          </a>
          <a href="https://clnk.in/uD8Q" className="store">
          <div className="all_store">
            <img src={Airtel} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD8S" className="store">
          <div className="trending_store">
            <img src={American_Express} className="cello" />
          </div>
          </a>

          <a href="https://clnk.in/uD8U" className="store">
          <div className="trending_store">
            <img src={BOB} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD8W" className="store">
          <div className="trending_store">
            <img src={Buddy_Score} className="Amazon" />
          </div>
          </a>
          <a href="https://clnk.in/uD8X" className="store">
          <div className="all_store">
            <img src={Credilio} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD8Z" className="store">
          <div className="trending_store">
            <img src={Creditlinks} className="cello" />
          </div>
          </a>

          <a href="https://clnk.in/uD82" className="store">
          <div className="trending_store">
            <img src={HDFC} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD84" className="store">
          <div className="trending_store">
            <img src={ICICI} className="Amazon" />
          </div>
          </a>
          <a href="https://clnk.in/uD85" className="store">
          <div className="all_store">
            <img src={IDFC} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD87" className="store">
          <div className="trending_store">
            <img src={IndusInd} />
          </div>
          </a>

          <a href="https://clnk.in/uD9e" className="store">
          <div className="trending_store">
            <img src={Max_Life} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD9g" className="store">
          <div className="trending_store">
            <img src={Nexo} className="Amazon" />
          </div>
          </a>
          <a href="https://clnk.in/uD9h" className="store">
          <div className="all_store">
            <img src={Paytm} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD9i" className="store">
          <div className="trending_store">
            <img src={Standard_Chartered} />
          </div>
          </a>

          <a href="https://clnk.in/uD9j" className="store">
          <div className="trending_store">
            <img src={Tata_Capital} />
          </div>
          </a>
          </div>

        </div>


        </div>
        <Footer />

    </>
  )
}

export default Finance;