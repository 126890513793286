
import Banner_2 from './images/Banner_2.png';

import './hero.css';


const HeroTwo = () => {


  return(
    <>
      <div className="silde-container mt-4">
        <img src={Banner_2} className="hero_img hero_img_1" />
      </div>
    </>
  )
}

export default HeroTwo;