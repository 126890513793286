import './App.css';
import Header from './components/Navbar';
import Hero from './components/Hero';
import HeroTwo from './components/HeroTwo';
import Topstores from './components/Topstores';
import Footer from './components/Footer';
import Coupon from './components/Coupon';
import Categories from './components/Categories';
import FAQ from './components/FAQ';
import PopupOuter from './components/PopupOuter';
import SportWear from './components/SportWear';
import Watches from './components/Watches';
import PTravel from './components/PTravel';


function App() {
  return (
  <>
  <Header />
  <PopupOuter />
  <Hero />
  <Topstores />
  {/* <Coupon /> */}
  <HeroTwo />
  <Categories />
  <SportWear />
  <Watches />
  <PTravel />
  {/* <FAQ /> */}
  <Footer />
  </>
  );
}

export default App;
