import './topstores.css';
import Amazon from './images/Store_Logo/Amazon.png';
import Myntra from './images/Store_Logo/Myntra.png';
import Ajio from './images/Store_Logo/Ajio.png';
import Microsoft from './images/Store_Logo/Microsoft.png';
import Croma_Retail from './images/Store_Logo/Croma_Retail.png';
import Dell from './images/Store_Logo/Dell.png';
import JBL from './images/Store_Logo/JBL.png';
import Samsung from './images/Store_Logo/Samsung.png';
import Arata from './images/Store_Logo/Arata.png';
import beardo from './images/Store_Logo/beardo.png';
import kapiva from './images/Store_Logo/kapiva.png';
import Mamaearth from './images/Store_Logo/Mamaearth.png';
import Forest_Essentials from './images/Store_Logo/Forest_Essentials.png';
import Flipkart from './images/Store_Logo/Flipkart.png';
import Adidas from './images/Store_Logo/Adidas.png';
import Puma from './images/Store_Logo/Puma.png';
import Nike from './images/Store_Logo/Nike.png';
import burger_king from './images/Store_Logo/burger_king.png';
import aurelia from './images/Store_Logo/aurelia.png';
import Fossil from './images/Store_Logo/Fossil.png';
import tata from './images/Store_Logo/tata.png';
import { NavLink } from 'react-router-dom';


const Topstores = () =>{
  return(
    <>
      <div className="trending_store_main">
        <div className="trending_store_heading">
          <h1>Trending Stores</h1>
        </div>

        <div className="trending_store_bottom">
          <div className="trending_store_bottom_sub">
          <a href="https://amzn.clnk.in/ewBO" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Amazon} />
          </div>
          </a>

          <a href="https://clnk.in/qMUz" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Myntra} className="myntra" />
          </div>
          </a>
          </div>
          <div className="trending_store_bottom_sub">
          <a href="https://clnk.in/qMTM" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Ajio} />
          </div>
          </a>

          <a href="https://clnk.in/qysl" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={tata} />
          </div>
          </a>
          </div>

        </div>



        <div className="trending_store_bottom">
          <div className="trending_store_bottom_sub">
          <a href="https://clnk.in/qMS4" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Microsoft} />
          </div>
          </a>

          <a href="https://clnk.in/qMSV" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Croma_Retail} />
          </div>
          </a>
          </div>
          <div className="trending_store_bottom_sub">
          <a href="https://clnk.in/qMTM" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Dell} />
          </div>
          </a>

          <a href="https://clnk.in/qMS2" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={JBL} />
          </div>
          </a>
          </div>

        </div>


        <div className="trending_store_bottom">
          <div className="trending_store_bottom_sub">
          <a href="https://clnk.in/uATh" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Samsung} />
          </div>
          </a>

          <a href="https://clnk.in/qU7f" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Arata} />
          </div>
          </a>
          </div>
          <div className="trending_store_bottom_sub">
          <a href="https://clnk.in/uATk" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={kapiva} />
          </div>
          </a>

          <a href="https://clnk.in/qysg" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Mamaearth} />
          </div>
          </a>
          </div>

        </div>


        <div className="trending_store_bottom">
          <div className="trending_store_bottom_sub">
          <a href="https://clnk.in/qU7U" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Forest_Essentials} />
          </div>
          </a>

          <a href="https://clnk.in/qMUz" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Flipkart} />
          </div>
          </a>
          </div>
          <div className="trending_store_bottom_sub">
          <a href="https://clnk.in/uBYu" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Adidas} />
          </div>
          </a>

          <a href="https://clnk.in/uErZ" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Puma} />
          </div>
          </a>
          </div>

        </div>

        <div className="trending_store_bottom">
          <div className="trending_store_bottom_sub">
          <a href="https://clnk.in/uErY" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Nike} />
          </div>
          </a>

          <a href="https://clnk.in/uBYA"  target={'_blank'} className="store">
          <div className="trending_store">
            <img src={burger_king} />
          </div>
          </a>
          </div>
          <div className="trending_store_bottom_sub">
          <a href="https://clnk.in/uBY2" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={aurelia} />
          </div>
          </a>

          <a href="https://clnk.in/uB0G" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Fossil} />
          </div>
          </a>
          </div>

        </div>

        <NavLink to="/CatePage" className="view_all_link">
        <div className="view_all view_all_stores">
         <a className="View_all_btn">Shop By Category</a>
        </div>
      </NavLink>

      </div>

    </>
  )
}

export default Topstores;