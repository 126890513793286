import './electronics.css';
import Header from "../Navbar";
import Footer from "../Footer";
import Game from "./images/Store_Logo/Game.png";
import Codashop from "./images/Store_Logo/Codashop.png";
import SEAGM from "./images/Store_Logo/SEAGM.png";
import OTT from "./images/Store_Logo/OTT.png";

const Gaming = () => {
  return(
    <>
          <Header />
      <div className="all_store_main">
        <div className="all_store_heading">
          <h1>Online Home & Kitchen Store</h1>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD9D" className="store">
          <div className="trending_store">
            <img src={Game} className="Amazon" />
          </div>
          </a>
          <a href="https://clnk.in/uD9E" className="store">
          <div className="all_store">
            <img src={Codashop} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD9H" className="store">
          <div className="trending_store">
            <img src={SEAGM} />
          </div>
          </a>

          <a href="https://clnk.in/uD9N" className="store">
          <div className="trending_store">
            <img src={OTT} />
          </div>
          </a>
          </div>

        </div>


        </div>
        <Footer />
    </>
  )
}

export default Gaming;