import Header from "../Navbar";
import Footer from "../Footer";
import { useState, useEffect } from 'react';
import Data from './Data.csv';
import Papa from 'papaparse';
import '../coupon.css';

const CouponPage = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(Data);
      const reader = response.body.getReader();
      const result = await reader.read();
      const decoder = new TextDecoder("utf-8");
      const csvData = decoder.decode(result.value);
      const parsedData = Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true
      }).data;
      setData(parsedData);
    };
    fetchData();
  }, []);
  return(
    <>
    <Header />
            <div className="coupon_main">
        <div className="coupon_heading">
          <h1>DEALS AND COUPON</h1>
        </div>

      <div className="coupon_bottom">
      {data.length ? (
        <div className="coupon_bottom_sub">
          {data.map((row, index) => (
          <a href="#">
          <div className="coupon">
            <div className="coupon_logo">
              <h2>{row.Merchant}</h2>
            </div>
            <div className="title_coupon">
              <h3>{row.Title}</h3>
              <p>{row.CouponCode}</p>
            </div>
          </div>
          </a>
          ))}
        </div>
        ) : null}

      </div>
      </div>
      <Footer />
    </>
  )
}

export default CouponPage;