import './electronics.css';
import Header from "../Navbar";
import Footer from "../Footer";
import Flipkart from './images/Store_Logo/Flipkart.png';
import Just_Fresh from './images/Store_Logo/Just_Fresh.png';
import Ministry_Nuts  from './images/Store_Logo/Ministry_Nuts.png';
import Pizza_Hut  from './images/Store_Logo/Pizza_Hut.png';
import Slurrpfarm  from './images/Store_Logo/Slurrpfarm.png';
import Soulfull  from './images/Store_Logo/Soulfull.png';
import Sova  from './images/Store_Logo/Sova.png';
import Swiggy  from './images/Store_Logo/Swiggy.png';
import nStore  from './images/Store_Logo/nStore.png';
import Amazon from './images/Store_Logo/Amazon.png';
import Zomato from './images/Store_Logo/Zomato.png';
import Dominos from './images/Store_Logo/Dominos.png';

const Food = () => {
  return(
    <>
        <Header />
      <div className="all_store_main">
        <div className="all_store_heading">
          <h1>Online Electronics Store</h1>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://amzn.clnk.in/ewBO" className="store">
          <div className="trending_store">
            <img src={Amazon} className="Amazon" />
          </div>
          </a>
          <a href="https://clnk.in/uDYG" className="store">
          <div className="trending_store">
            <img src={Just_Fresh} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDYK" className="store">
          <div className="trending_store">
            <img src={Ministry_Nuts} />
          </div>
          </a>

          <a href="https://clnk.in/qMUz" className="store">
          <div className="trending_store">
            <img src={Flipkart} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDYN" className="store">
          <div className="trending_store">
            <img src={Pizza_Hut} />
          </div>
          </a>

          <a href="https://clnk.in/uDYO" className="store">
          <div className="trending_store">
            <img src={Slurrpfarm} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">

          <a href="https://clnk.in/uDYR" className="store">
          <div className="trending_store">
            <img src={Soulfull} />
          </div>
          </a>
          <a href="https://clnk.in/uDYT" className="store">
          <div className="all_store">
            <img src={Sova} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDYU" className="store">
          <div className="all_store">
            <img src={Swiggy} />
          </div>
          </a>

          <a href="https://clnk.in/uDYV" className="store">
          <div className="all_store">
            <img src={nStore} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://www.zomato.com/" className="store">
          <div className="all_store">
            <img src={Zomato} className="Dominos" />
          </div>
          </a>

          <a href="https://pizzaonline.dominos.co.in/menu" className="store">
          <div className="all_store">
            <img src={Dominos} className="Dominos" />
          </div>
          </a>
          </div>
        </div>
        </div>
        <Footer />
    </>
  )
}

export default Food;