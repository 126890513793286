import './electronics.css';
import Header from "../Navbar";
import Footer from "../Footer";
import BigRock from "./images/Store_Logo/BigRock.png";
import CBD from "./images/Store_Logo/CBD.png";
import Cloudways from "./images/Store_Logo/Cloudways.png";
import Economist from "./images/Store_Logo/Economist.png";
import PayZapp from "./images/Store_Logo/PayZapp.png";
import Lombard from "./images/Store_Logo/Lombard.png";
import MK from "./images/Store_Logo/MK.jpeg";
import Readle from "./images/Store_Logo/Readle.png";
import SSLs from "./images/Store_Logo/SSLs.png";
import Remote from "./images/Store_Logo/Remote.png";
import Shopify from "./images/Store_Logo/Shopify.png";
import Zoomin from "./images/Store_Logo/Zoomin.png";
import Envato from "./images/Store_Logo/Envato.png";
import Placeit from "./images/Store_Logo/Placeit.png";
import GoDaddy from "./images/Store_Logo/GoDaddy.png";
import Hostinger from "./images/Store_Logo/Hostinger.png";
import Microsoft from "./images/Store_Logo/Microsoft.png";
import McAfee from "./images/Store_Logo/McAfee.jpeg";
import Notion from "./images/Store_Logo/Notion.png";
import Picsart from "./images/Store_Logo/Picsart.png";
import Parallels from "./images/Store_Logo/Parallels.png";
import Adobe from "./images/Store_Logo/Adobe.png";
import Acko from "./images/Store_Logo/Acko.svg";
import digitalocean from "./images/Store_Logo/digitalocean.svg";

const Service = () => {
  return(
    <>
                <Header />
      <div className="all_store_main">
        <div className="all_store_heading">
          <h1>Online Services Store</h1>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uErc" className="store">
          <div className="trending_store">
            <img src={Envato}  />
          </div>
          </a>
          <a href="https://clnk.in/uErd" className="store">
          <div className="trending_store">
            <img src={Placeit} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://in.godaddy.com/" className="store">
          <div className="trending_store">
            <img src={GoDaddy} />
          </div>
          </a>

          <a href="https://www.hostinger.in/" className="store">
          <div className="trending_store">
            <img src={Hostinger} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEoK" className="store">
            <div className="trending_store">
              <img src={Shopify} />
              </div>
          </a>
          <a href="https://clnk.in/uErk" className="store">
          <div className="trending_store">
            <img src={Microsoft} className="Expedia" />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uErl" className="store">
          <div className="trending_store">
            <img src={McAfee} />
          </div>
          </a>

          <a href="https://clnk.in/uEro" className="store">
          <div className="trending_store">
            <img src={Notion} className="cello" />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uErt" className="store">
            <div className="trending_store">
              <img src={Parallels} />
              </div>
          </a>
          <a href="https://www.adobe.com/" className="store">
          <div className="trending_store">
            <img src={Adobe} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEru" className="store">
          <div className="trending_store">
            <img src={Acko} />
          </div>
          </a>

          <a href="https://clnk.in/uErz" className="store">
          <div className="trending_store">
            <img src={digitalocean} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEou" className="store">
          <div className="trending_store">
            <img src={BigRock} className="Amazon" />
          </div>
          </a>
          <a href="https://clnk.in/uEow" className="store">
          <div className="trending_store">
            <img src={CBD} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEox" className="store">
          <div className="trending_store">
            <img src={Cloudways} />
          </div>
          </a>

          <a href="https://clnk.in/uEoA" className="store">
          <div className="trending_store">
            <img src={Economist} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEoB" className="store">
          <div className="trending_store">
            <img src={PayZapp}  />
          </div>
          </a>
          <a href="https://clnk.in/uEoE" className="store">
          <div className="trending_store">
            <img src={Lombard} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEoF" className="store">
          <div className="trending_store">
            <img src={MK} className="cello" />
          </div>
          </a>

          <a href="https://clnk.in/uEoH" className="store">
          <div className="trending_store">
            <img src={Readle} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEoI" className="store">
          <div className="trending_store">
            <img src={SSLs}  />
          </div>
          </a>
          <a href="https://clnk.in/uEoJ" className="store">
          <div className="trending_store">
            <img src={Remote} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uErr" className="store">
          <div className="trending_store">
            <img src={Picsart} />
          </div>
          </a>

          <a href="https://clnk.in/uEoL" className="store">
          <div className="trending_store">
            <img src={Zoomin} />
          </div>
          </a>
          </div>

        </div>



        </div>
        <Footer />
    </>
  )
}

export default Service;
