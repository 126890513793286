import Popup from "./Popup";
import './popup.css';
import { useState } from "react";
import Logo from "./images/Logo.svg";
import home_1 from "./images/home_1.jpeg";
import home_2 from "./images/home_2.jpeg";
import home_3 from "./images/home_3.jpeg";

const PopupOuter = () => {
  const [buttomPopup, setButtonPopup] = useState(false);
 return(
  <>
  <main className="popup_main">
    <button className="mobile_btn" onClick={() => setButtonPopup(true)} ><i class="fa-solid fa-mobile"></i></button>
  </main>
  <Popup trigger={buttomPopup} setTrigger={setButtonPopup}>
    <div className="popup_inner_main">
    {/* <div className="popup_header">
      <div className="logo_popup">
      <img src={Logo} />
      <h1>App Less</h1>
      </div>
      <div className="popup_greater_sign">
      <i class="fa-solid fa-greater-than"></i>
      </div>
      <div className="popup_mobile">
      <i class="fa-solid fa-mobile"></i>
      </div>
    </div> */}
    <div className="popup_bottom">
      {/* <h1>Add APPLESS app to your apple device</h1> */}
      <p>1. Tap <b>Share</b> button in your browser</p>
      <img src={home_1} />
      <p>2. In the pop-up menu actions, scroll down and tap on the <b>Add to Home Screen</b></p>
      <img src={home_2} className="home_img_2" />
      <p>3. Tap on the <b>Add</b> button on the Top-Right corner, to complete adding the app</p>
      <img src={home_3} />
      <p>The app will be accessible from your iPhone home screen</p>
    </div>
    </div>
  </Popup>
  </>
 )
}

export default PopupOuter;