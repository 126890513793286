import './topstores.css';
import MakeMytrip from './Pages/images/Store_Logo/MakeMytrip.png';
import Goibibo from './Pages/images/Store_Logo/Goibibo.png';
import Adani from './Pages/images/Store_Logo/Adani.png';
import Agoda from './Pages/images/Store_Logo/Agoda.png';

const PTravel = () => {
  return(
    <>
            <div className="trending_store_main trending_store_main-bg">
        <div className="trending_store_heading">
          <h1>Travel Store</h1>
        </div>

        <div className="trending_store_bottom">
          <div className="trending_store_bottom_sub">
          <a href="https://clnk.in/uDZP" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={MakeMytrip} />
          </div>
          </a>

          <a href="https://clnk.in/uDZS" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Goibibo} />
          </div>
          </a>
          </div>
          <div className="trending_store_bottom_sub">
          <a href="https://clnk.in/uDZX" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Adani} />
          </div>
          </a>

          <a href="https://www.agoda.com/" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Agoda} />
          </div>
          </a>
          </div>

        </div>
        </div>
    </>
  )
}

export default PTravel;