import React, { useState } from "react";
import './footer.css';
import insta from "./images/insta.svg";
import facebook from "./images/facebook.svg";
import youtube from "./images/youtube.svg";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return(
    <>
      {/* <div className="footer">
        <div className="footer_top">
        <div className="web_name">
            <h2>APP LESS</h2>
          </div>
          <ul>
            <li><a href="">Terms & Condition</a></li>
            <li><a href="">Privacy Policy</a></li>
            <li><a href="">Refert & Earn</a></li>
          </ul>

        </div>
        <div className="footer_bottom">
          <p>© 2010 – 2024 App Less All Rights Reserved</p>
        </div>
      </div> */}
                  <div className="Footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-5 col-12 ft-1">
                            <h3><span>APP</span>LESS</h3>
                            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laborum ea quo ex ullam laboriosam magni totam, facere eos iure voluptate.</p>
                            <div className="footer-icons">
                                <img src={insta} />
                                <img src={facebook} />
                                <img src={youtube} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-12 ft-2">
                            <h5>Top Categories</h5>
                            <ul>
                              <li className="nav-item">
                              <NavLink to="/Electronics">Electronics</NavLink>
                              </li>
                                <li className="nav-item">
                                    <NavLink to="/Fashion">Fashion</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/Beauty">Beauty & Beauty</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/Service">Services</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12 ft-3">
                            <h5>Top Stores</h5>
                              <p><a href="https://amzn.clnk.in/iXkc">Amazon</a></p>
                              <p><a href="https://www.flipkart.com/">Flipkart</a></p>
                              <p><a href="https://clnk.in/uEi7">Myntra</a></p>
                              <p><a href="https://clnk.in/uDW2">Titan</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Last-footer'>
                <p>© 2010 – 2024 App Less All Rights Reserved</p>
            </div>

    </>
  )
}

export default Footer;