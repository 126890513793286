import './electronics.css';
import Header from "../Navbar";
import Footer from "../Footer";
import AbeBooks from './images/Store_Logo/AbeBooks.png';
import Bookscape from './images/Store_Logo/Bookscape.png';
import iberlibro from './images/Store_Logo/iberlibro.png';
import Appsumo from './images/Store_Logo/Appsumo.png';
import Blinkist from './images/Store_Logo/Blinkist.png';
import British_Council from './images/Store_Logo/British_Council.png';
import Clapingo from './images/Store_Logo/Clapingo.png';
import Coursera from './images/Store_Logo/Coursera.png';
import Edureka from './images/Store_Logo/Edureka.png';
import Elsa from './images/Store_Logo/Elsa.png';
import Eventtrix from './images/Store_Logo/Eventtrix.png';
import IDP from './images/Store_Logo/IDP.png';
import udemy from './images/Store_Logo/udemy.png';
import Rosetta from './images/Store_Logo/Rosetta.png';
import MasterClass from './images/Store_Logo/MasterClass.png';
import Knowlegehut from './images/Store_Logo/Knowlegehut.png';
import Amazon from './images/Store_Logo/Amazon.png';
import Flipkart from './images/Store_Logo/Flipkart.png';

const Books = () => {
  return(
    <>
    <Header />
<div className='all_store_main' >
<div className="all_store_heading">
<h1>Online Books & Education Store</h1>
{/* <div className="all_store_heading_search">

  <input type="text" placeholder="Search" />
</div> */}
</div>

<div className="all_store_bottom">
<div className="all_store_bottom_sub">
<a href="https://clnk.in/uCL2" className="store">
<div className="all_store">
  <img src={AbeBooks} className="AbeBooks" />
</div>
</a>

<a href="https://www.udemy.com" className="store">
<div className="all_store">
  <img src={udemy} />
</div>
</a>
</div>
<div className="all_store_bottom_sub">
<a href="https://clnk.in/uCMi" className="store">
<div className="all_store">
  <img src={iberlibro} />
</div>
</a>

<a href="https://amzn.clnk.in/ewBO" className="store">
          <div className="trending_store">
            <img src={Amazon} />
          </div>
          </a>
</div>

</div>
<div className="all_store_bottom">
<div className="all_store_bottom_sub">
<a href="https://clnk.in/qMUz" className="store">
          <div className="trending_store">
            <img src={Flipkart} />
          </div>
          </a>


<a href="https://clnk.in/uCMm" className="store">
<div className="all_store">
  <img src={British_Council} />
</div>
</a>
</div>
<div className="all_store_bottom_sub">
<a href="https://clnk.in/uCMn" className="store">
<div className="all_store">
  <img src={Clapingo} />
</div>
</a>

<a href="https://clnk.in/uCMo" className="store">
<div className="all_store">
  <img src={Coursera} />
</div>
</a>
</div>

</div>
<div className="all_store_bottom">
<div className="all_store_bottom_sub">
<a href="https://clnk.in/uCMq" className="store">
<div className="all_store">
  <img src={Edureka} />
</div>
</a>

<a href="https://clnk.in/uDSe" className="store">
<div className="all_store">
  <img src={Elsa} />
</div>
</a>
</div>
<div className="all_store_bottom_sub">
<a href="https://clnk.in/uDSp" className="store">
<div className="all_store">
  <img src={Eventtrix} />
</div>
</a>

<a href="https://clnk.in/uDSt" className="store">
<div className="all_store">
  <img src={IDP} />
</div>
</a>
</div>

</div>
<div className="all_store_bottom">
<div className="all_store_bottom_sub">
<a href="https://clnk.in/uCMc" className="store">
<div className="all_store">
  <img src={Bookscape} />
</div>
</a>


<a href="https://clnk.in/uDSv" className="store">
<div className="all_store">
  <img src={Rosetta} />
</div>
</a>
</div>
<div className="all_store_bottom_sub">
<a href="https://www.masterclass.com/" className="store">
<div className="all_store">
  <img src={MasterClass} className="MasterClass" />
</div>
</a>

<a href="https://clnk.in/uDSy" className="store">
<div className="all_store">
  <img src={Knowlegehut} className="Knowlegehut" />
</div>
</a>
</div>
</div>

<div className="all_store_bottom">
<div className="all_store_bottom_sub">
<a href="https://clnk.in/uCMj" className="store">
<div className="all_store">
  <img src={Appsumo} className="Appsumo" />
</div>
</a>
<a href="https://clnk.in/uCMk" className="store">
<div className="all_store">
  <img src={Blinkist} />
</div>
</a>
</div>
</div>

</div>

<Footer />

    </>
  )
}

export default Books;