import './electronics.css';
import Header from "../Navbar";
import Footer from "../Footer";
import Fernsnpetals from './images/Store_Logo/Fernsnpetals.png';
import Black_Box from './images/Store_Logo/Black_Box.png';
import Indian_Gifts from './images/Store_Logo/Indian_Gifts.png';
import Flipkart from './images/Store_Logo/Flipkart.png';
import Amazon from './images/Store_Logo/Amazon.png';
const Gifts = () =>{
  return(
    <>
          <Header />
      <div className="all_store_main">
        <div className="all_store_heading">
          <h1>Online Flowers & Gifts Store</h1>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDYo" className="store">
          <div className="trending_store">
            <img src={Fernsnpetals} className="Amazon" />
          </div>
          </a>
          <a href="https://clnk.in/uDYu" className="store">
          <div className="trending_store">
            <img src={Indian_Gifts} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDYw" className="store">
          <div className="trending_store">
            <img src={Black_Box} />
          </div>
          </a>

          <a href="https://clnk.in/qMUz" className="store">
          <div className="trending_store">
            <img src={Flipkart} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://amzn.clnk.in/ewBO" className="store">
          <div className="trending_store">
            <img src={Amazon} className="Amazon" />
          </div>
          </a>
          </div>
        </div>
        </div>
        <Footer />
    </>
  )
}

export default Gifts;