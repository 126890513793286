import './topstores.css';
import Titan from "./Pages/images/Store_Logo/Titan.png";
import Fossil from "./Pages/images/Store_Logo/Fossil.png";
import Fastrack from "./Pages/images/Store_Logo/Fastrack.png";
import Timex from "./Pages/images/Store_Logo/Timex.png";

const Watches = () => {
  return(
    <>
      <div className="trending_store_main trending_store_main-bg">
        <div className="trending_store_heading">
          <h1>Watches Store</h1>
        </div>

        <div className="trending_store_bottom">
          <div className="trending_store_bottom_sub">
          <a href="https://clnk.in/uDW2" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Titan} />
          </div>
          </a>

          <a href="https://clnk.in/uErX" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Fossil} />
          </div>
          </a>
          </div>
          <div className="trending_store_bottom_sub">
          <a href="https://clnk.in/uErW" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Fastrack} />
          </div>
          </a>

          <a href="https://clnk.in/uEr4" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Timex} />
          </div>
          </a>
          </div>

        </div>
        </div>
    </>
  )
}

export default Watches;