import './electronics.css';
import Header from "../Navbar";
import Footer from "../Footer";
import Feedsmart from "./images/Store_Logo/Feedsmart.png";
import Firstcry from "./images/Store_Logo/Firstcry.png";
import Flipkart from "./images/Store_Logo/Flipkart.png";
import Amazon from "./images/Store_Logo/Amazon.png";

const Kids = () => {
  return(
    <>
      <Header />
      <div className="all_store_main">
        <div className="all_store_heading">
          <h1>Online Home & Kitchen Store</h1>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uCLX" className="store">
          <div className="trending_store">
            <img src={Feedsmart} className="Amazon" />
          </div>
          </a>
          <a href="https://clnk.in/uCLW" className="store">
          <div className="all_store">
            <img src={Firstcry} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://www.flipkart.com/" className="store">
          <div className="trending_store">
            <img src={Flipkart} />
          </div>
          </a>

          <a href="https://amzn.clnk.in/iXkc" className="store">
          <div className="trending_store">
            <img src={Amazon} />
          </div>
          </a>
          </div>

        </div>
        </div>
        <Footer />
    </>
  )
}

export default Kids;