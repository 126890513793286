import './categories.css';
import { NavLink } from "react-router-dom";
import fashion_cate from './images/fashion_cate.png';
import banking_cate from './images/banking_cate.png';
import beauty_cate from './images/beauty_cate.png';
import finance from './images/finance.png';
import education_cate from './images/education_cate.png';
import electronics_cate from './images/electronics_cate.png';
import food from './images/food.png';
import kids from './images/kids.png';
import home_kitchen_cate from './images/home_kitchen_cate.png';
import flowers from './images/flowers.png';
import gaming_cate from './images/gaming_cate.png';
import others from './images/others.png';
import travel from './images/travel.png';
import services from './images/services.png';
import books from './images/books.png';


const Categories = () => {
  return(
    <>
      <div className="categories_main">
        <div className="categories_heading">
          <h1>Top Categories</h1>
        </div>
        <div className="categories_bottom">
          <div className="categories_bottom_sub">
          <NavLink to="/Fashion">
          <div className="categories">
            <img src={fashion_cate} />
          </div>
          </NavLink>
          <div className="categories">
            <img src={banking_cate} />
          </div>
          </div>
          <div className="categories_bottom_sub">
          <NavLink to="Beauty">
          <div className="categories">
            <img src={beauty_cate} />
          </div>
          </NavLink>
          <NavLink to="Finance">
          <div className="categories">
            <img src={finance} />
          </div>
          </NavLink>
          </div>
          <div className="categories_bottom_sub">
            <NavLink to="/Books">
              <div className="categories">
                <img src={education_cate} />
              </div>
          </NavLink>
          <NavLink to="/Electronics">
          <div className="categories">
            <img src={electronics_cate} />
          </div>
          </NavLink>
          </div>
          </div>

          {/* <div className="categories_bottom">
          <div className="categories_bottom_sub">
          <NavLink to="/Food" >
          <div className="categories">
            <img src={food} />
          </div>
          </NavLink>
          <NavLink to="/Kids">
          <div className="categories">
            <img src={kids} />
          </div>
          </NavLink>
          </div>
          <div className="categories_bottom_sub">
          <NavLink to="Home">
          <div className="categories">
            <img src={home_kitchen_cate} />
          </div>
          </NavLink>
          <NavLink to="Gaming">
          <div className="categories">
            <img src={gaming_cate} />
          </div>
          </NavLink>
          </div>
          <div className="categories_bottom_sub">
          <NavLink to="/Gifts">
          <div className="categories">
            <img src={flowers} />
          </div>
          </NavLink>
          <div className="categories">
            <img src={others} />
          </div>
          </div>
        </div>

        <div className="categories_bottom">
          <div className="categories_bottom_sub">
          <NavLink to="/Travel" >
          <div className="categories">
            <img src={travel} />
          </div>
          </NavLink>
          <NavLink to="/Service">
          <div className="categories">
            <img src={services} />
          </div>
          </NavLink>
          </div>
        </div> */}
      <NavLink to="/CatePage" className="view_all_link">
        <div className="view_all">
         <a className="View_all_btn">View All Categories</a>
        </div>
      </NavLink>
      </div>
    </>
  )
}

export default Categories;