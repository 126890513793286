import './electronics.css';
import Header from "../Navbar";
import Footer from "../Footer";
import Amazon from './images/Store_Logo/Amazon.png';
import Banggood from './images/Store_Logo/Banggood.png';
import Borosil from './images/Store_Logo/Borosil.png';
import CELLO from './images/Store_Logo/CELLO.png';
import Flipkart from './images/Store_Logo/Flipkart.png';
import Lilysilk from './images/Store_Logo/Lilysilk.png';
import Trident from './images/Store_Logo/Trident.png';
import MyWake from './images/Store_Logo/MyWake.png';
import Neelkamal from './images/Store_Logo/Neelkamal.png';
import OnsiteGo from './images/Store_Logo/OnsiteGo.png';
import Pepperfry from './images/Store_Logo/Pepperfry.png';
import Sleepycat from './images/Store_Logo/Sleepycat.png';

const Home = () => {
  return(
    <>
      <Header />
      <div className="all_store_main">
        <div className="all_store_heading">
          <h1>Online Home & Kitchen Store</h1>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://amzn.clnk.in/ewBO" className="store">
          <div className="trending_store">
            <img src={Amazon} className="Amazon" />
          </div>
          </a>
          <a href="https://clnk.in/uDSW" className="store">
          <div className="all_store">
            <img src={Banggood} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD8t" className="store">
          <div className="trending_store">
            <img src={Borosil} />
          </div>
          </a>

          <a href="https://clnk.in/uD8u" className="store">
          <div className="trending_store">
            <img src={CELLO} className="cello" />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/qMUz" className="store">
          <div className="trending_store">
            <img src={Flipkart} />
          </div>
          </a>
          <a href="https://clnk.in/uD8z" className="store">
          <div className="all_store">
            <img src={Lilysilk} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD8C" className="store">
          <div className="trending_store">
            <img src={Trident} />
          </div>
          </a>

          <a href="https://clnk.in/uD8G" className="store">
          <div className="trending_store">
            <img src={MyWake} />
          </div>
          </a>
          </div>

        </div>

        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD8H" className="store">
          <div className="trending_store">
            <img src={Neelkamal} />
          </div>
          </a>
          <a href="https://clnk.in/uD8J" className="store">
          <div className="all_store">
            <img src={OnsiteGo} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD8K" className="store">
          <div className="trending_store">
            <img src={Pepperfry} />
          </div>
          </a>

          <a href="https://clnk.in/uD8L" className="store">
          <div className="trending_store">
            <img src={Sleepycat} />
          </div>
          </a>
          </div>

        </div>


        </div>
        <Footer />
    </>
  )
}
export default Home;