import './electronics.css';
import Header from "../Navbar";
import Footer from "../Footer";
import WOW from "./images/Store_Logo/WOW.png";
import Myntra from "./images/Store_Logo/Myntra.png";
import Flipkart from "./images/Store_Logo/Flipkart.png";
import Amazon from "./images/Store_Logo/Amazon.png";
import Mamaearth from "./images/Store_Logo/Mamaearth.png";
import Dabur from "./images/Store_Logo/Dabur.png";
import beardo from "./images/Store_Logo/beardo.png";
import Forest_Essentials from "./images/Store_Logo/Forest_Essentials.png";
import Oneimg from "./images/Store_Logo/Oneimg.png";
import Acne_Squad from "./images/Store_Logo/Acne_Squad.jpeg";
import Arata from "./images/Store_Logo/Arata.png";
import Bold_Care from "./images/Store_Logo/Bold_Care.png";
import ClickOnCare from "./images/Store_Logo/ClickOnCare.png";
import Coco_Soul from "./images/Store_Logo/Coco_Soul.avif";
import Conscious from "./images/Store_Logo/Conscious.png";
import DotKey from "./images/Store_Logo/DotKey.png";
import Foxtale from "./images/Store_Logo/Foxtale.jpeg";
import Gritzo from "./images/Store_Logo/Gritzo.png";
import Gynoveda from "./images/Store_Logo/Gynoveda.png";
import Hair_Originals from "./images/Store_Logo/Hair_Originals.png";
import HealthKart from "./images/Store_Logo/HealthKart.png";
import Healthmug from "./images/Store_Logo/Healthmug.png";
import Hkvitals from "./images/Store_Logo/Hkvitals.png";
import Hyphen from "./images/Store_Logo/Hyphen.png";
import HyugaLife from "./images/Store_Logo/HyugaLife.png";
import Ibacosmetics from "./images/Store_Logo/Ibacosmetics.png";
import Ironpandafit from "./images/Store_Logo/Ironpandafit.avif";
import Innovist from "./images/Store_Logo/Innovist.png";
import kapiva from "./images/Store_Logo/kapiva.png";
import Kiro from "./images/Store_Logo/Kiro.png";
import Lelo from "./images/Store_Logo/Lelo.png";
import Lookfantastic from "./images/Store_Logo/Lookfantastic.png";
import LoveBeauty from "./images/Store_Logo/LoveBeauty.avif";
import mcaffeine from "./images/Store_Logo/mcaffeine.png";
import Muscleblaze from "./images/Store_Logo/Muscleblaze.png";
import My_Upchar from "./images/Store_Logo/My_Upchar.png";
import Netmeds from "./images/Store_Logo/Netmeds.png";
import NuLeaf from "./images/Store_Logo/NuLeaf.png";
import Olive_Young from "./images/Store_Logo/Olive_Young.png";
import Orgatre from "./images/Store_Logo/Orgatre.png";
import Paulas_Choice from "./images/Store_Logo/Paulas_Choice.png";
import Pique from "./images/Store_Logo/Pique.png";
import Renee from "./images/Store_Logo/Renee.png";
import SS_Beauty from "./images/Store_Logo/SS_Beauty.png";
import Simple from "./images/Store_Logo/Simple.png";
import SoulTree from "./images/Store_Logo/SoulTree.png";
import Soulflower from "./images/Store_Logo/Soulflower.avif";
import Asaya from "./images/Store_Logo/Asaya.png";

const Beauty = () => {
  return(
    <>
      <Header />
      <div className="all_store_main">
        <div className="all_store_heading">
          <h1>Online Health & Beauty Store</h1>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEi5" target="_blank" className="store">
          <div className="trending_store">
            <img src={WOW} className="Amazon" />
          </div>
          </a>
          <a href="https://clnk.in/uEi7" target="_blank" className="store">
          <div className="all_store">
            <img src={Myntra} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://www.flipkart.com/" target="_blank" className="store">
          <div className="trending_store">
            <img src={Flipkart} />
          </div>
          </a>

          <a href="https://amzn.clnk.in/iXkc" target="_blank" className="store">
          <div className="trending_store">
            <img src={Amazon} />
          </div>
          </a>
          </div>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEja" target="_blank" className="store">
          <div className="trending_store">
            <img src={Mamaearth} className="Amazon" />
          </div>
          </a>
          <a href="https://clnk.in/uEjb" target="_blank" className="store">
          <div className="all_store">
            <img src={Dabur} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEjc" target="_blank" className="store">
          <div className="trending_store">
            <img src={beardo} />
          </div>
          </a>

          <a href="https://clnk.in/uEjf" target="_blank" className="store">
          <div className="trending_store">
            <img src={Forest_Essentials} />
          </div>
          </a>
          </div>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEjl" target="_blank" className="store">
          <div className="trending_store">
            <img src={Oneimg} className="cello" />
          </div>
          </a>
          <a href="https://clnk.in/uEjq" target="_blank" className="store">
          <div className="all_store">
            <img src={Acne_Squad} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEjt" target="_blank" className="store">
          <div className="trending_store">
            <img src={Arata} />
          </div>
          </a>

          <a href="https://clnk.in/uEjv" target="_blank" className="store">
          <div className="trending_store">
            <img src={Bold_Care} />
          </div>
          </a>
          </div>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEjy" target="_blank" className="store">
          <div className="trending_store">
            <img src={ClickOnCare} />
          </div>
          </a>
          <a href="https://clnk.in/uEjD" target="_blank" className="store">
          <div className="all_store">
            <img src={Coco_Soul} className="cello" />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEjG" target="_blank" className="store">
          <div className="trending_store">
            <img src={Conscious} />
          </div>
          </a>

          <a href="https://clnk.in/uEjK" target="_blank" className="store">
          <div className="trending_store">
            <img src={DotKey} />
          </div>
          </a>
          </div>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEj5" target="_blank" className="store">
          <div className="trending_store">
            <img src={Foxtale} />
          </div>
          </a>
          <a href="https://clnk.in/uEj6" target="_blank" className="store">
          <div className="all_store">
            <img src={Gritzo} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEj7" target="_blank" className="store">
          <div className="trending_store">
            <img src={Gynoveda} />
          </div>
          </a>

          <a href="https://clnk.in/uEj8" target="_blank" className="store">
          <div className="trending_store">
            <img src={Hair_Originals} />
          </div>
          </a>
          </div>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEmu" target="_blank" className="store">
          <div className="trending_store">
            <img src={HealthKart} />
          </div>
          </a>
          <a href="https://clnk.in/uEmx" target="_blank" className="store">
          <div className="all_store">
            <img src={Healthmug} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEmy" target="_blank" className="store">
          <div className="trending_store">
            <img src={Hkvitals} />
          </div>
          </a>

          <a href="https://clnk.in/uEmC" target="_blank" className="store">
          <div className="trending_store">
            <img src={Hyphen} />
          </div>
          </a>
          </div>
        </div>


        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEmF" className="store">
          <div className="trending_store">
            <img src={HyugaLife} />
          </div>
          </a>
          <a href="https://clnk.in/uEmG" className="store">
          <div className="all_store">
            <img src={Ibacosmetics} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEmK" className="store">
          <div className="trending_store">
            <img src={Ironpandafit} className="panda" />
          </div>
          </a>

          <a href="https://clnk.in/uEmC" className="store">
          <div className="trending_store">
            <img src={Innovist} />
          </div>
          </a>
          </div>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEmO" className="store">
          <div className="trending_store">
            <img src={kapiva} />
          </div>
          </a>
          <a href="https://clnk.in/uEmV" className="store">
          <div className="all_store">
            <img src={Kiro} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEmY" className="store">
          <div className="trending_store">
            <img src={Lelo} />
          </div>
          </a>

          <a href="https://www.lookfantastic.co.in/" className="store">
          <div className="trending_store">
            <img src={Lookfantastic} />
          </div>
          </a>
          </div>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEm2" className="store">
          <div className="trending_store">
            <img src={LoveBeauty} />
          </div>
          </a>
          <a href="https://clnk.in/uEm3" className="store">
          <div className="all_store">
            <img src={mcaffeine} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEm4" className="store">
          <div className="trending_store">
            <img src={Muscleblaze} />
          </div>
          </a>

          <a href="https://clnk.in/uEnc" className="store">
          <div className="trending_store">
            <img src={My_Upchar} />
          </div>
          </a>
          </div>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEng" className="store">
          <div className="trending_store">
            <img src={Netmeds} />
          </div>
          </a>
          <a href="https://clnk.in/uEnk" className="store">
          <div className="all_store">
            <img src={NuLeaf} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEnU" className="store">
          <div className="trending_store">
            <img src={Olive_Young} />
          </div>
          </a>

          <a href="https://clnk.in/uEnV" className="store">
          <div className="trending_store">
            <img src={Orgatre} />
          </div>
          </a>
          </div>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEnX" className="store">
          <div className="trending_store">
            <img src={Paulas_Choice} />
          </div>
          </a>
          <a href="https://clnk.in/uEn1" className="store">
          <div className="all_store">
            <img src={Pique} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEn3" className="store">
          <div className="trending_store">
            <img src={Renee} />
          </div>
          </a>

          <a href="https://clnk.in/uEn5" className="store">
          <div className="trending_store">
            <img src={SS_Beauty} className="Expedia" />
          </div>
          </a>
          </div>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEn7" className="store">
          <div className="trending_store">
            <img src={Simple} />
          </div>
          </a>
          <a href="https://clnk.in/uEn8" className="store">
          <div className="all_store">
            <img src={SoulTree} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEob" className="store">
          <div className="trending_store">
            <img src={Soulflower} />
          </div>
          </a>

          <a href="https://clnk.in/uEof" className="store">
          <div className="trending_store">
            <img src={Asaya} />
          </div>
          </a>
          </div>
        </div>

        </div>
        <Footer />
    </>
  )
}

export default Beauty;