import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Electronics from './components/Pages/Electronics';
import Books from './components/Pages/Books';
import Gifts from './components/Pages/Gifts';
import Food from './components/Pages/Food';
import Travel from './components/Pages/Travel';
import Home from './components/Pages/Home';
import Finance from './components/Pages/Finance';
import Gaming from './components/Pages/Gaming';
import Kids from './components/Pages/Kids';
import Beauty from './components/Pages/Beauty';
import Service from './components/Pages/Service';
import Fashion from './components/Pages/Fashion';
import CatePage from './components/Pages/CatePage';
import CouponPage from './components/Pages/CouponPage';
import About from './components/Pages/About';



const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/Books",
    element: <Books />,
  },
  {
    path: "/Electronics",
    element: <Electronics />,
  },
  {
    path: "/Gifts",
    element: <Gifts />,
  },
  {
    path: "/Food",
    element: <Food />,
  },
  {
    path: "/Travel",
    element: <Travel />,
  },
  {
    path: "/Home",
    element: <Home />,
  },
  {
    path: "/Finance",
    element: <Finance />,
  },
  {
    path: "/Gaming",
    element: <Gaming />,
  },
  {
    path: "/Kids",
    element: <Kids />,
  },
  {
    path: "/Beauty",
    element: <Beauty />,
  },
  {
    path: "/Service",
    element: <Service />,
  },
  {
    path: "/Fashion",
    element: <Fashion />,
  },
  {
    path: "/CatePage",
    element: <CatePage />,
  },
  {
    path: "/CouponPage",
    element: <CouponPage />,
  },
  {
    path: "/About",
    element: <About />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
