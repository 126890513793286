import './electronics.css';
import Header from "../Navbar";
import Footer from "../Footer";
import MakeMytrip from './images/Store_Logo/MakeMytrip.png';
import Goibibo from './images/Store_Logo/Goibibo.png';
import Etihad from './images/Store_Logo/Etihad.png';
import Qatar from './images/Store_Logo/Qatar.png';
import Adani from './images/Store_Logo/Adani.png';
import Agoda from './images/Store_Logo/Agoda.png';
import Air_Vistara from './images/Store_Logo/Air_Vistara.png';
import Accor_Hotels from './images/Store_Logo/Accor_Hotels.jpeg';
import Airasia from './images/Store_Logo/Airasia.png';
import Airalo from './images/Store_Logo/Airalo.png';
import Airpaz from './images/Store_Logo/Airpaz.png';
import Cleartrip from './images/Store_Logo/Cleartrip.png';
import EaseMyTrip from './images/Store_Logo/EaseMyTrip.png';
import Emirates from './images/Store_Logo/Emirates.png';
import Expedia from './images/Store_Logo/Expedia.png';
import HappyFares from './images/Store_Logo/HappyFares.png';
import Hotels_com from './images/Store_Logo/Hotels_com.png';
import Hotelscombined from './images/Store_Logo/Hotelscombined.png';
import Italiarail from './images/Store_Logo/Italiarail.png';
import Kiwi from './images/Store_Logo/Kiwi.png';
import Klook from './images/Store_Logo/Klook.png';
import Luxury_Escapes from './images/Store_Logo/Luxury_Escapes.png';
import Malaysia from './images/Store_Logo/Malaysia.png';
import Oberoi_Hotels from './images/Store_Logo/Oberoi_Hotels.svg';
import Singapore_Airlines from './images/Store_Logo/Singapore_Airlines.png';
import Tiqets from './images/Store_Logo/Tiqets.svg';
import Travelstart from './images/Store_Logo/Travelstart.png';
import Trip from './images/Store_Logo/Trip.png';

const Travel = () => {
  return(
    <>
              <Header />
      <div className="all_store_main">
        <div className="all_store_heading">
          <h1>Online Travel Store</h1>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDZP" className="store">
          <div className="trending_store">
            <img src={MakeMytrip} className="Amazon" />
          </div>
          </a>
          <a href="https://clnk.in/uDZS" className="store">
          <div className="trending_store">
            <img src={Goibibo} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDZU" className="store">
          <div className="trending_store">
            <img src={Etihad} />
          </div>
          </a>

          <a href="https://clnk.in/uDZV" className="store">
          <div className="trending_store">
            <img src={Qatar} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDZX" className="store">
          <div className="trending_store">
            <img src={Adani} className="Amazon" />
          </div>
          </a>
          <a href="https://www.agoda.com/" className="store">
          <div className="trending_store">
            <img src={Agoda} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDZ2" className="store">
          <div className="trending_store">
            <img src={Air_Vistara} />
          </div>
          </a>

          <a href="https://www.accorhotels.com/" className="store">
          <div className="trending_store">
            <img src={Accor_Hotels} className="Accor_Hotels" />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDZ6" className="store">
          <div className="trending_store">
            <img src={Airasia} className="Amazon" />
          </div>
          </a>
          <a href="https://clnk.in/uDZ8" className="store">
          <div className="trending_store">
            <img src={Airalo} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDZ9" className="store">
          <div className="trending_store">
            <img src={Airpaz} />
          </div>
          </a>

          <a href="https://clnk.in/uD0c" className="store">
          <div className="trending_store">
            <img src={Cleartrip} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD0x" className="store">
          <div className="trending_store">
            <img src={EaseMyTrip} className="Amazon" />
          </div>
          </a>
          <a href="https://www.emirates.com/in/english/" className="store">
          <div className="trending_store">
            <img src={Emirates} className="Emirates" />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://www.expedia.co.in/" className="store">
          <div className="trending_store">
            <img src={Expedia}  className="Expedia" />
          </div>
          </a>

          <a href="https://clnk.in/uD0F" className="store">
          <div className="trending_store">
            <img src={HappyFares} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD0x" className="store">
          <div className="trending_store">
            <img src={Hotels_com} className="Expedia" />
          </div>
          </a>
          <a href="https://www.hotelscombined.com/" className="store">
          <div className="trending_store">
            <img src={Hotelscombined} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD72" className="store">
          <div className="trending_store">
            <img src={Italiarail}  />
          </div>
          </a>

          <a href="https://clnk.in/uD74" className="store">
          <div className="trending_store">
            <img src={Kiwi} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD77" className="store">
          <div className="trending_store">
            <img src={Klook} />
          </div>
          </a>
          <a href="https://clnk.in/uD8e" className="store">
          <div className="trending_store">
            <img src={Luxury_Escapes} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD8f" className="store">
          <div className="trending_store">
            <img src={Malaysia}  />
          </div>
          </a>

          <a href="https://clnk.in/uD8i" className="store">
          <div className="trending_store">
            <img src={Oberoi_Hotels} />
          </div>
          </a>
          </div>

        </div>

        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD8l" className="store">
          <div className="trending_store">
            <img src={Singapore_Airlines} />
          </div>
          </a>
          <a href="https://clnk.in/uD8m" className="store">
          <div className="trending_store">
            <img src={Tiqets} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uD8o" className="store">
          <div className="trending_store">
            <img src={Travelstart}  />
          </div>
          </a>

          <a href="https://clnk.in/uD8p" className="store">
          <div className="trending_store">
            <img src={Trip} />
          </div>
          </a>
          </div>

        </div>

        </div>
        <Footer />
    </>
  )
}

export default Travel;