import './about.css';
import Header from "../Navbar";
import Footer from "../Footer";

const About = () => {
  return(
    <>
    <Header />
      <div className="about_main">
        <div className="about_heading">
          <h1>About App Less</h1>
        </div>
        <div className="about_bottom">
          <p>Welcome to Appless, your all-in-one solution for streamlining your mobile experience. Tired of cluttering your phone with multiple apps for different needs and constantly being bombarded with notifications? Say goodbye to app overload and hello to simplicity with Appless.</p>
          <p>With Appless, there's no need to install countless apps for various purposes. Whether you're shopping, ordering food, booking tickets, or staying updated with the latest news, Appless has you covered. Our platform integrates all your essential services and features into one convenient app, saving you time, storage space, and hassle.</p>
          <p>But that's not all. We know everyone loves a good deal, which is why we've partnered with your favorite stores and brands to bring you exclusive discounts and offers—all in one place. From electronics to fashion, groceries to travel, Appless provides unbeatable deals tailored to your preferences, helping you save money while you shop.</p>
          <p>Forget about juggling between different apps and missing out on the best deals. With Appless, you can declutter your phone, streamline your life, and enjoy savings without sacrificing convenience. Join the Appless community today and experience a simpler, more efficient way to navigate the digital world.</p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default About;