import './electronics.css';
import Header from "../Navbar";
import Footer from "../Footer";
import Myntra from "./images/Store_Logo/Myntra.png";
import Fabindia from "./images/Store_Logo/Fabindia.png";
import aurelia from "./images/Store_Logo/aurelia.png";
import Adidas from "./images/Store_Logo/Adidas.png";
import Ajio from "./images/Store_Logo/Ajio.png";
import Biba from "./images/Store_Logo/Biba.png";
import Celio from "./images/Store_Logo/Celio.png";
import Fastrack from "./images/Store_Logo/Fastrack.png";
import Fossil from "./images/Store_Logo/Fossil.png";
import Flipkart from "./images/Store_Logo/Flipkart.png";
import Amazon from "./images/Store_Logo/Amazon.png";
import beardo from "./images/Store_Logo/beardo.png";
import Nike from "./images/Store_Logo/Nike.png";
import Puma from "./images/Store_Logo/Puma.png";
import TheManCompany from "./images/Store_Logo/TheManCompany.png";
import Titan from "./images/Store_Logo/Titan.png";
import W from "./images/Store_Logo/W.png";
import Wildcraft from "./images/Store_Logo/Wildcraft.png";
import Timex from "./images/Store_Logo/Timex.png";
import Bacca from "./images/Store_Logo/Bacca.png";
import Aimer from "./images/Store_Logo/Aimer.png";
import Aldo from "./images/Store_Logo/Aldo.png";
import Caprese from "./images/Store_Logo/Caprese.png";
import Bliss_Club from "./images/Store_Logo/Bliss_Club.png";
import Bernardelli from "./images/Store_Logo/Bernardelli.png";
import Columbia from "./images/Store_Logo/Columbia.png";
import Ganga from "./images/Store_Logo/Ganga.png";
import Giva from "./images/Store_Logo/Giva.png";
import Hardaddy from "./images/Store_Logo/Hardaddy.png";
import Haute_Sauce from "./images/Store_Logo/Haute_Sauce.png";
import Lenskart from "./images/Store_Logo/Lenskart.png";
import Masaba from "./images/Store_Logo/Masaba.png";
import Jack_Jones from "./images/Store_Logo/Jack_Jones.png";
import Jockey from "./images/Store_Logo/Jockey.png";
import Kingdom_White from "./images/Store_Logo/Kingdom_White.png";
import Koskii from "./images/Store_Logo/Koskii.jpeg";
import Hummel from "./images/Store_Logo/Hummel.png";
import Hidesign from "./images/Store_Logo/Hidesign.png";
import Samsonite from "./images/Store_Logo/Samsonite.png";
import Only from "./images/Store_Logo/Only.png";
import Orvis from "./images/Store_Logo/Orvis.png";
import Nykaa_Fashion from "./images/Store_Logo/Nykaa_Fashion.svg";
import Marc_Jacobs from "./images/Store_Logo/Marc_Jacobs.png";
import Marks_Spencer from "./images/Store_Logo/Marks_Spencer.png";
import Liberty from "./images/Store_Logo/Liberty.png";
import Mufti from "./images/Store_Logo/Mufti.png";
import Rangriti from "./images/Store_Logo/Rangriti.png";
import zudio from "./images/Store_Logo/zudio.png";


const Fashion = () => {
  return(
    <>
                <Header />
      <div className="all_store_main">
        <div className="all_store_heading">
          <h1>Online Fashion Store</h1>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEi7" className="store">
          <div className="trending_store">
            <img src={Myntra} className="Myntra" />
          </div>
          </a>
          <a href="https://clnk.in/uErG" className="store">
          <div className="trending_store">
            <img src={Fabindia} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uErI" className="store">
          <div className="trending_store">
            <img src={aurelia} />
          </div>
          </a>

          <a href="https://clnk.in/uErK" className="store">
          <div className="trending_store">
            <img src={Adidas} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uErQ" className="store">
          <div className="trending_store">
            <img src={Ajio} />
          </div>
          </a>
          <a href="https://clnk.in/uErT" className="store">
          <div className="trending_store">
            <img src={Biba} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uErU" className="store">
          <div className="trending_store">
            <img src={Celio} />
          </div>
          </a>

          <a href="https://clnk.in/uErW" className="store">
          <div className="trending_store">
            <img src={Fastrack} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uErX" className="store">
          <div className="trending_store">
            <img src={Fossil} />
          </div>
          </a>
          <a href="https://www.flipkart.com/" className="store">
          <div className="trending_store">
            <img src={Flipkart} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://amzn.clnk.in/iXkc" className="store">
          <div className="trending_store">
            <img src={Amazon} />
          </div>
          </a>

          <a href="https://clnk.in/uEjc" className="store">
          <div className="trending_store">
            <img src={beardo} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uErY" className="store">
          <div className="trending_store">
            <img src={Nike} />
          </div>
          </a>
          <a href="https://clnk.in/uErZ" className="store">
          <div className="trending_store">
            <img src={Puma} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEr0" className="store">
          <div className="trending_store">
            <img src={TheManCompany} />
          </div>
          </a>

          <a href="https://clnk.in/uDW2" className="store">
          <div className="trending_store">
            <img src={Titan} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEtG" className="store">
          <div className="trending_store">
            <img src={Marks_Spencer} />
          </div>
          </a>
          <a href="https://clnk.in/uEtJ" className="store">
          <div className="trending_store">
            <img src={Liberty} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEtL" className="store">
          <div className="trending_store">
            <img src={Mufti} />
          </div>
          </a>

          <a href="https://clnk.in/uEtN" className="store">
          <div className="trending_store">
            <img src={Rangriti} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEsg" className="store">
          <div className="trending_store">
            <img src={Ganga} />
          </div>
          </a>
          <a href="https://clnk.in/uEsh" className="store">
          <div className="trending_store">
            <img src={Giva} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEtu" className="store">
          <div className="trending_store">
            <img src={Nykaa_Fashion} />
          </div>
          </a>

          <a href="https://clnk.in/uEsm" className="store">
          <div className="trending_store">
            <img src={Haute_Sauce} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEr1" className="store">
          <div className="trending_store">
            <img src={W} />
          </div>
          </a>
          <a href="https://clnk.in/uEr2" className="store">
          <div className="trending_store">
            <img src={Wildcraft} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEr4" className="store">
          <div className="trending_store">
            <img src={Timex} />
          </div>
          </a>

          <a href="https://clnk.in/uEr7" className="store">
          <div className="trending_store">
            <img src={Bacca} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEr1" className="store">
          <div className="trending_store">
            <img src={Jack_Jones} />
          </div>
          </a>
          <a href="https://clnk.in/uEsx" className="store">
          <div className="trending_store">
            <img src={Jockey} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEsA" className="store">
          <div className="trending_store">
            <img src={Kingdom_White} />
          </div>
          </a>

          <a href="https://clnk.in/uEsC" className="store">
          <div className="trending_store">
            <img src={Koskii} className="cello" />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEtk" className="store">
          <div className="trending_store">
            <img src={Only} />
          </div>
          </a>
          <a href="https://clnk.in/uEtl" className="store">
          <div className="trending_store">
            <img src={Orvis} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEsl" className="store">
          <div className="trending_store">
            <img src={Hardaddy} />
          </div>
          </a>

          <a href="https://clnk.in/uEtD" className="store">
          <div className="trending_store">
            <img src={Marc_Jacobs} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEtc" className="store">
          <div className="trending_store">
            <img src={Hummel} />
          </div>
          </a>
          <a href="https://clnk.in/uEtd" className="store">
          <div className="trending_store">
            <img src={Hidesign} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEtj" className="store">
          <div className="trending_store">
            <img src={Samsonite} />
          </div>
          </a>

          <a href="https://www.zudio.com/" className="store">
          <div className="trending_store">
            <img src={zudio} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEr9" className="store">
          <div className="trending_store">
            <img src={Aimer} />
          </div>
          </a>
          <a href="https://clnk.in/uEsa" className="store">
          <div className="trending_store">
            <img src={Aldo} className="Expedia" />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEsb" className="store">
          <div className="trending_store">
            <img src={Caprese} />
          </div>
          </a>

          <a href="https://clnk.in/uEsc" className="store">
          <div className="trending_store">
            <img src={Bliss_Club} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEsd" className="store">
          <div className="trending_store">
            <img src={Bernardelli} />
          </div>
          </a>
          <a href="https://clnk.in/uEsf" className="store">
          <div className="trending_store">
            <img src={Columbia} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uEsp" className="store">
          <div className="trending_store">
            <img src={Lenskart} />
          </div>
          </a>

          <a href="https://clnk.in/uEsq" className="store">
          <div className="trending_store">
            <img src={Masaba} />
          </div>
          </a>
          </div>

        </div>


        </div>
        <Footer />
    </>
  )
}

export default Fashion;