import './topstores.css';
import Adidas from './images/Store_Logo/Adidas.png';
import Puma from './images/Store_Logo/Puma.png';
import Nike from './images/Store_Logo/Nike.png';
import Jockey from "./Pages/images/Store_Logo/Jockey.png";

const SportWear = () => {
  return(
    <>
            <div className="trending_store_main trending_store_main-bg my-4">
        <div className="trending_store_heading">
          <h1>Sport Wear Store</h1>
        </div>

        <div className="trending_store_bottom">
          <div className="trending_store_bottom_sub">
          <a href="https://clnk.in/uErZ" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Puma} />
          </div>
          </a>

          <a href="https://clnk.in/uErK" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Adidas} />
          </div>
          </a>
          </div>
          <div className="trending_store_bottom_sub">
          <a href="https://clnk.in/uErY" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Nike} />
          </div>
          </a>

          <a href="https://clnk.in/uEsx" target={'_blank'} className="store">
          <div className="trending_store">
            <img src={Jockey} />
          </div>
          </a>
          </div>

        </div>
        </div>

    </>
  )
}

export default SportWear;