import React, { useState } from "react";
import './navbar.css';
import Logo from './images/Logo.svg';

import { NavLink } from "react-router-dom";

const Header = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  return(
    <>
      {/* <div className="navbar">
        <div className="nav_left">
          <div className="logo">
            <img src={Logo} />
          </div>
        </div>

        <div className="nav_mid">
          <div className="search_icon">
              <span class="material-symbols-outlined">
                search
              </span>
            </div>
          <input type="text" placeholder="Search" />
        </div>

        <div class="nav_right">
        <ul>
          <li><a href="#">ADD TO CHROME</a></li>
        </ul>
      </div>
      </div>

    <div className="nav_bottom">
    <div className="all_categories">
      <div className="all_categories_icon">
        <span class="material-symbols-outlined">
          menu
        </span>
        </div>
        <Link to="/AllStoresPage">All Stores</Link>
      </div>
      <ul>
        <li><Link to="/">Fashion</Link></li>
        <li><Link to="/">Electronics</Link></li>
        <li><Link to="/">Home appliances</Link></li>
        <li><Link to="/">Clothing & Shoes</Link></li>
        <li><Link to="/">Health & Beauty</Link></li>
        <li><Link to="/">Travel</Link></li>
      </ul>
      <div className="refer">
        <a href="#">Refer and Earn!</a>
      </div>
    </div> */}

<nav className="main-nav">
        {/* 1st logo part  */}
        <div className="logo">
         <NavLink to="/"><img src={Logo} /></NavLink>
        </div>

        {/* 2nd menu part  */}
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }>
          <ul>
            <li>
              <NavLink to="/Fashion">Fashion</NavLink>
            </li>
            <li>
              <NavLink to="/Electronics">Electronics</NavLink>
            </li>
            <li>
              <NavLink to="/Finance">Finance</NavLink>
            </li>

            <li>
              <NavLink to="/Travel">Travel</NavLink>
            </li>
            <li>
              <NavLink to="/About">About Us</NavLink>
            </li>
          </ul>
        </div>

        {/* 3rd social media links */}
        <div className="social-media">

          {/* hamburget menu start  */}
          <div className="hamburger-menu">
            <a href="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
            <span class="material-symbols-outlined">
              menu
            </span>
            </a>
          </div>
        </div>
      </nav>

    </>

  )
}

export default Header;