import Banner_1 from './images/Banner_1.png';
import Banner_1_m from './images/Banner_1_m.png';
import './hero.css';

const Hero = () => {
  return(
    <>
      <div className="silde-container">
        <img src={Banner_1} className="hero_img " />
      </div>
    </>
  )
}

export default Hero;