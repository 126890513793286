import './electronics.css';
import Header from "../Navbar";
import Footer from "../Footer";
import Acer from './images/Store_Logo/Acer.png';
import Adorama from './images/Store_Logo/Adorama.png';
import AliExpress from './images/Store_Logo/AliExpress.png';
import Boat from './images/Store_Logo/Boat.png';
import Gonoise from './images/Store_Logo/Gonoise.png';
import lenovo from './images/Store_Logo/lenovo.png';
import Oneplus from './images/Store_Logo/Oneplus.png';
import Oppo from './images/Store_Logo/Oppo.png';
import Avira from './images/Store_Logo/Avira.png';
import Banggood from './images/Store_Logo/Banggood.png';
import BodyPedia from './images/Store_Logo/BodyPedia.png';
import Boult from './images/Store_Logo/Boult.png';
import Just_Watches from './images/Store_Logo/Just_Watches.png';
import LONGER from './images/Store_Logo/LONGER.png';
import oukitel from './images/Store_Logo/oukitel.png';
import RealMe from './images/Store_Logo/RealMe.png';
import Shopsy from './images/Store_Logo/Shopsy.png';
import Snapdeal from './images/Store_Logo/Snapdeal.png';
import UPERFECT from './images/Store_Logo/UPERFECT.jpeg';
import Martinic from './images/Store_Logo/Martinic.png';
import Amazon from './images/Store_Logo/Amazon.png';
import Croma_Retail from './images/Store_Logo/Croma_Retail.png';
import Dell from './images/Store_Logo/Dell.png';
import Flipkart from './images/Store_Logo/Flipkart.png';
import Samsung from './images/Store_Logo/Samsung.png';
import JBL from './images/Store_Logo/JBL.png';
import Microsoft from './images/Store_Logo/Microsoft.png';
import tata from './images/Store_Logo/tata.png';
const Electronics = () => {
  return(
    <>
    <Header />
      <div className="all_store_main">
        <div className="all_store_heading">
          <h1>Online Electronics Store</h1>
        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://amzn.clnk.in/ewBO" className="store">
          <div className="trending_store">
            <img src={Amazon} className="Amazon" />
          </div>
          </a>
          <a href="https://clnk.in/qMSV" className="store">
          <div className="trending_store">
            <img src={Croma_Retail} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/qMTM" className="store">
          <div className="trending_store">
            <img src={Dell} />
          </div>
          </a>

          <a href="https://www.flipkart.com/" className="store">
          <div className="trending_store">
            <img src={Flipkart} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uATh" className="store">
          <div className="trending_store">
            <img src={Samsung} />
          </div>
          </a>

          <a href="https://clnk.in/qMS2" className="store">
          <div className="trending_store">
            <img src={JBL} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">

          <a href="https://clnk.in/qysl" className="store">
          <div className="trending_store">
            <img src={tata} />
          </div>
          </a>
          <a href="https://clnk.in/uDSI" className="store">
          <div className="all_store">
            <img src={Boat} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDSA" className="store">
          <div className="all_store">
            <img src={Acer} />
          </div>
          </a>

          <a href="https://clnk.in/uDSB" className="store">
          <div className="all_store">
            <img src={Adorama} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDSD" className="store">
          <div className="all_store">
            <img src={AliExpress} />
          </div>
          </a>
          <a href="https://clnk.in/uDTx" className="store">
          <div className="all_store">
            <img src={RealMe} />
          </div>
          </a>


          </div>

        </div>

        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDSK" className="store">
          <div className="all_store">
            <img src={Gonoise} className="Gonoise" />
          </div>
          </a>

          <a href="https://clnk.in/uDSM" className="store">
          <div className="all_store">
            <img src={lenovo} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDSN" className="store">
          <div className="all_store">
            <img src={Oneplus} className="Oneplus" />
          </div>
          </a>

          <a href="https://clnk.in/uDST" className="store">
          <div className="all_store">
            <img src={Oppo} />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDSU" className="store">
          <div className="all_store">
            <img src={Avira} />
          </div>
          </a>

          <a href="https://clnk.in/uDSW" className="store">
          <div className="all_store">
            <img src={Banggood} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDSY" className="store">
          <div className="all_store">
            <img src={BodyPedia} className="BodyPedia" />
          </div>
          </a>

          <a href="https://clnk.in/uDS0" className="store">
          <div className="all_store">
            <img src={Boult} className="Boult" />
          </div>
          </a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDTk" className="store">
          <div className="all_store">
            <img src={Just_Watches} />
          </div>
          </a>
          <a href="https://clnk.in/uDTp" className="store">
          <div className="all_store">
            <img src={LONGER} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDTq" className="store">
          <div className="all_store">
            <img src={oukitel} />
          </div>
          </a>
          <a href="https://clnk.in/qMS4" className="store">
<div className="trending_store">
  <img src={Microsoft} />
</div>
</a>
          </div>

        </div>
        <div className="all_store_bottom">
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDTA" className="store">
          <div className="all_store">
            <img src={Shopsy} />
          </div>
          </a>

          <a href="https://clnk.in/uDTE" className="store">
          <div className="all_store">
            <img src={Snapdeal} />
          </div>
          </a>
          </div>
          <div className="all_store_bottom_sub">
          <a href="https://clnk.in/uDTG" className="store">
          <div className="all_store">
            <img src={UPERFECT} className="UPERFECT" />
          </div>
          </a>

          <a href="https://clnk.in/uDTN" className="store">
          <div className="all_store">
            <img src={Martinic} className="Martinic" />
          </div>
          </a>
          </div>

        </div>
        </div>
        <Footer />
    </>
  )
}

export default Electronics;